<template>
  <div class="home">
    <!-- 头部 -->
    <MainHeaderVue/>
    <div class="ASD_AA">
    <div class="mian_card">
      <div class="cc_s2">
         <div class="maincard_title">
           <img src="@/assets/ASDF.png" alt="">
         </div>
         <div class="title_title">
        <!-- 花乡间民宿是柏里水乡以太行村居的建筑风貌，石、木、竹为主的建筑选材，秉承一院一主题特色设计有童玩主题童趣部落、田园主题可依筱筑、禅意主题山云舍、轻奢主题竹逸居、山野主题那野及石窑乡舍等多种房型，融合美食、文创、运动、康养、娱乐、体验等多元业态，配备专业民宿管家服务，满足家庭亲子、团建聚会、康养度假多元需求实现一站式度假 -->
          {{baliintroDetile.desc}}
         </div>
      </div>
      <div class="min_video">
           <div class="cc_s1">
        <Video ref="videos"/>
      </div>
     
      <!-- <div class="cc_s"> 
        <Thumbnail/>
      </div> -->
      </div>
    </div>
   
    <div class="fg_hj">
      <div class="">当季主推</div>  
      <CardGraph />
    </div></div>
   <div class="main">
     <LeftrightSwiperVue/>
    </div>
    <div class="ASDFG">
      <QualitycontrolVue/>
    </div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
// import Header from "@/components/header.vue";

import Footer from "@/components/footer.vue";
import Video from "@/views/index/video.vue";
import LeftrightSwiperVue from '@/components/leftrightSwiper.vue';
import Thumbnail from './index/thumbnail.vue';
import CardGraph from "./index/cardGraph.vue"
import MainHeaderVue from './index/mainHeader.vue';
import QualitycontrolVue from '@/components/Qualitycontrol.vue';
import request from '@/utils/request';
import { mapGetters, mapActions } from 'vuex';
export default {
      computed: {
    ...mapGetters('idModule', ['idList']),
  },
  name: "HomeView",
  components: {
    Footer,
    // Header,
    LeftrightSwiperVue,
    Video,
    Thumbnail,
    CardGraph,
    MainHeaderVue,
    QualitycontrolVue
  },
  data() {
    return {
      carouselSwitched: false,
      // 数据合集
      dataCollection:[],
      video:"",
      // 当地主推
      localItems:[],
      carouselItems: [
       
      ],
      idListA:{},
      baliintroDetile:{}
    };
  },
  mounted(){
    const storedData = localStorage.getItem('destinationData');
    if (storedData) {
        const destinationData = JSON.parse(storedData);
        // 使用 vuex 存储数据
        this.$store.commit('setDestinationData', destinationData);
    }
    this.getData()
    // this.getLocal()
  },
   watch: {
    '$store.state.destinationData': {
      handler(oldParams, newParams) {
        // 监听路由参数的变化
        console.log("newParams", newParams, "oldParams", oldParams);
        const newId = newParams.id;
        const oldId = oldParams.id;

        if (newId !== oldId) {
          // 路由参数发生变化时，调用获取数据的方法
          // this.getData();
          // this.getLocal()
          window.scrollTo(0, 0);
        }
      },
      deep: true, // 深度监听，确保能够监听到对象属性的变化
    },
  },
      computed: {
    // 从 Vuex 或其他状态管理工具中获取参数
    destinationData() {
      return this.$store.state.destinationData;
    },
  },
      created() {
    this.fetchData();
  },
  methods: {
     ...mapActions('idModule', ['feachidList']),
    async fetchData() {
    await this.feachidList();
   this.idListA =this.$store.state.idModule.idList[0]
   console.log("首页i的LIST",this.idListA)
   this.getJQJ()
  },
  // 获取花乡间民宿 
    getJQJ(){
       request.get(`/web/article/info?id=${this.idListA.zidingyi.hua_xiang_min_su_article}`)
        .then(res => {
          // console.log("res",res)
          if (res.code === 0) {
            this.baliintroDetile = res.data
            // console.log("景区简介",this.baliintroDetile)
          } else {
             this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          //  
        });
    },
    // 获取当地主推
    getLocal(){
       let params = {
        page:1,
        limit:100,
        cate_id: 36
      }
      console.log("opopop",params)
      request.get("/web/article/getList",{params})
        .then(res => {
          console.log("res",res)
          if (res.code === 0) {
            this.localItems = res.data
            console.log("文章列表localItems",this.localItems)
          } else {
             this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          //  
        });
    },
    // 获取轮播图
    getData(){
      request.get("/web/common/indexInfo")
        .then(res => {
          console.log("res",res)
          if (res.code === 0) {
            this.dataCollection = res.data;
            this.carouselItems = res.data.loop
            // this.video = res.data.video
            this.$refs.videos.video = res.data.video;
            console.log("78979",this.dataCollection)
          } else {
             this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          //  
        });
    }                       
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  // height: 1080px;
  position: relative;
}

.top_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
}

.content {
  overflow: hidden;
}

.title {
  display: flex;
  margin-top: 453px;
  color: #fff;
  font-size: 72px;
  font-weight: 400;
  line-height: 108px;
  justify-content: center;
  align-items: center;
}

.msg {
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remok {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 21px;
}
.main{
  // margin-top: 50px;
  // margin-bottom: 50px;
  /* height: 800px; */
}
.mian_card{
 display: flex;
 justify-content: space-between;
 width: calc(100% - 390px);
 margin: 0 auto;
 height: 500px;
//  align-items: center;
  margin-top: 68px;
//  border: 1px solid red;
}
.maincard_title{
 display: flex;
}
.title_title{
 font-size: 13px;
 text-align: justify;
 line-height: 26px;
 text-indent: 2em;
 letter-spacing: 1px;
 display: flex;
//  border: 1px solid;
 align-items: end;
 /* margin-right: 20px
; */
 font-weight: 600;
 /* margin-top: 25px
; */
}
.min_video{
 width: 62%;
  // border: 1px solid;
  // display: flex;
  // justify-content: space-between;

}
.cc_s{
  margin: 80px 0;
   width: 35%;
  // height:330px;
 position: relative;
  overflow: hidden;
}
.cc_s1{
  // width: 100%;
  // height: 498px;
    width: 100%;
    height: 450px;
    position: relative;
    top: 50px;
}

.cc_s2{
 width: 35%;
 display: flex;
 flex-direction: column;
 justify-content: space-evenly;
 /* position: relative; */
 /* top: 102px
; */
//  border: 1px solid red;
}
/* 在 Video 组件中添加样式，确保视频充满整个容器 */
.video-container {
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 使用 object-fit: cover 保持视频比例并填充整个容器 */
}
.fg_hj{
  padding-top: 64px;
  font-size: 32px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #232323;
text-align: center;
    width: calc( 100% - 290px);
margin: 0 auto;
}
.ASDFG{
  // margin-top: 64px;
  // width: 1191px;
// margin: 0 auto;
// margin-bottom: 108px;
}
.ASD_AA{
  background-image: url("@/assets/headerbg.png");
    // background-image: url("@/assets/hh.png");
  // background-size: contain;
   background-size: 100% 90%;
  object-fit: cover;
   overflow: hidden;
}
/* You can remove the .scale-effect class */
</style>
<style >

/* body {
  overflow-x: auto;
} */

</style>