<template>
  <div class="footer">
    <div class="footer_cont">
      <div class="footer_list">
        <div class="footer_li" v-for="(item, index) in list" :key="index">
          <div class="title" @click="godetule(item)">{{ item.name }}</div>
          <div
            class="li"
            v-for="(items, indexx) in item.child"
            :key="indexx + 'a'"
            @click="godetule(items)"
          >
            {{ items.name }}
          </div>
        </div>
      </div>
      <div class="info_cont">
        <div class="info_left">
          <div class="info_title">酒店预订电话</div>
          <div class="info_b">{{idListr.hotel_phone}}</div>
          <div class="info_text">24小时服务电话，免长话费，全年无休</div>
        </div>
        <div class="info_right">
          <div class="code_li" v-for="(item,index) in imgList" :key="index+'v'">
             <el-image 
  style="width: 88px; height: 88px;"
    :src="item.url" 
    :preview-src-list="srcList">
  </el-image>
        
            <div class="code_texy">{{item.name}}</div>
          </div>
      
        </div>
      </div>
    </div>
    <div class="filings">
      <div class="filings_li">
       {{idListr.site_copyright}}
      </div>
      <div class="filings_li">{{idListr.site_record}}</div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import { mapGetters, mapActions } from 'vuex';
export default {
       computed: {
    ...mapGetters('idModule', ['idList']),
  },
  data() {
    return {
      list: [
      ],
      imgList:[
      
      ],
      srcList:[
       
      ],
      srcList1:[],
      idListr:{}
    };
  },
     mounted() {
     this.getData()
  },
      created() {
    this.fetchData();
  },
    methods: {
       ...mapActions('idModule', ['feachidList']),
    async fetchData() {
    await this.feachidList();
 this.idListr = this.$store.state.idModule.idList[0];
// const imageUrls = {
//   applet_log: "http://blgw.39ti.com/temp/20231204/508756897c381a306af6e89b6cf84124.png",
//   public_log: "http://blgw.39ti.com/temp/20231204/deb39fd0b7233151d5bf52fa6417d9ea.png",
//   site_logo: "http://blgw.39ti.com/temp/20231204/2d7cbef0ecd53309004b659a47880aee.png",
//   // 其他属性...
// };

const { applet_log, public_log } = this.idListr;

const imageUrls = [applet_log, public_log].filter(url => url);
// this.srcList = this.imgList

// console.log(imgURl);
// const imageUrls = {
//   applet_log: "http://blgw.39ti.com/temp/20231204/508756897c381a306af6e89b6cf84124.png",
//   public_log: "http://blgw.39ti.com/temp/20231204/deb39fd0b7233151d5bf52fa6417d9ea.png",
//   site_logo: "http://blgw.39ti.com/temp/20231204/2d7cbef0ecd53309004b659a47880aee.png",
//   // 其他属性...
// };
const newArray = imageUrls.map((url, index) => {
  let name = "";

  // 根据索引设置名称
  if (index === 0) {
    name = "小程序";
  } else if (index === 1) {
    name = "公众号";
  }

  return { url, name };
});

console.log("newArray",newArray);
this.imgList = newArray
this.srcList = imageUrls

// console.log("imgUrls",this.imgList,"srcList",this.srcList);


//    console.log("898989",this.idList)
  },
  
        // 获取表头
    getData(){
      request.get("/web/itemcate/getCateList")
        .then(res => {
          console.log("res",res)
          if (res.code === 0) {
            this.list = res.data
            
          } else {
             this.$message.error(res.data.msg);
          }
        })
        // .catch(e => {
        //    
        // });
    },
    godetule(e){
       if (e.show_type === 3) {
    // 外部链接跳转
    // window.location.href = e.url; 
     window.open(e.url,'_blank')
    return;
  }
  window.scrollTo(0, 0);
const routes = {
        "首页": "/",
        "景区概括": "/introduce/bailitotal",
        "柏里百景": "/introduce/bailiBaijing",
        "全景VR游": "/introduce/vr",
        "投诉建议":"/ServiceCenter",
        "联系我们":"/lianxi",
        // "新闻资讯":"/NewsInformation",
        "招标信息":"/NewsInformation/inviteTenders",
        "招聘信息":"/NewsInformation/inviteList"
    };

    const path = routes[e.name] || (e.show_type === 1 ? (e.style == 1 ? "/Homestay/Stonekiln" : "/NewsInformation") :(e.style_detail == 1 ? "/Homestay/stonekillDetile" : "/InformationDetile") );
      this.$store.commit('setDestinationData', {    id:e.id,
      cover:e.cover,
      title:e.name,
      msg:e.note,
      style:e.style
      });
        // 将数据存储在localStorage中
    localStorage.setItem('destinationData', JSON.stringify({
        id: e.id,
        cover: e.cover,
        title: e.name,
        msg: e.note,
        style: e.style
    }));
     console.log("跳转页面", e);
    this.$router.push({
        path,
        params:{
          id:e.id
        }
    }).catch(() => {});

    // console.log("跳转页面", e);
}
  }
};
</script>

<style scoped>
.footer {
  width: 100%;
  /* height: 508px; */
  /* background-color: #1E937E; */
  background: rgba(25, 44, 59, 0.85);
}

.footer_cont {
  width: calc(100% - 364px);
  /* height: 492px; */
  padding-top: 20px;
  margin: 0 auto;
}

.footer_list {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.footer_li {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
@media screen and (max-width: 1200px) {
  .footer_li{
  font-size: 11px;
  }
}
.li {
  padding: 3px 0;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .li {
  padding: 5px 0;
  }
}
.title {
  padding-bottom: 12px;
  cursor: pointer;
}


.title:hover,
.li:hover {
  color: #FFDEA0;
}

/*  */
.info_cont {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0 6px 0;
}

.info_left {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.info_b {
  font-size: 21px;
  font-weight: 600;
  padding: 14px 0 4px 0;
}

.info_right {
  display: flex;
  align-items: center;
}
.code_li:nth-child(1) {
  margin-right: 20px;
}
.code {
  width: 80px;
  height: 80px;
  /* background-color: orange; */
  background-image: url("@/assets/Rectangle 28.png");
  background-size: cover;
  margin: 0 auto;
}

.code_texy {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  padding-top: 10px;
}

/*  */
.filings {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.filings_li {
  padding: 8px 0;
  /* margin-bottom: 8px; */
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>