// store/modules/menu.js

import request from '@/utils/request';

const state = {
  menuData: [],
  isMobile: false,//是否是手机端
};

const mutations = {
  setMenuData(state, data) {
    state.menuData = data;
  },
  // 修改值
  SET(state, obj) {
    state[obj.key] = obj.value;
  },
};

const actions = {
  async fetchMenuData({ commit }) {
    try {
      // 发起实际的 API 请求，替换以下 URL 为你的后台接口地址
      const response = await request.get('/web/itemcate/getCateList');

      // 假设后台返回的数据结构如下：
      // const realMenuData = response.data.data;

      // 使用真实的菜单数据更新 Vuex 中的数据
      commit('setMenuData', response.data);
    } catch (error) {
      console.error('Error fetching menu data:', error);
      // 处理请求失败的情况，比如显示错误信息等
    }
  },
  /* 
  设置用户角色权限：财务 操作
  */
  setIsMobile({ commit }, isMobile) {
    console.log(isMobile, '=============isMobile')
    commit('SET', { key: 'isMobile', value: isMobile })
  },
};

const getters = {
  menuData: (state) => state.menuData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
