<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <keep-alive>   -->
    <router-view />
    <!-- </keep-alive>   -->
  </div>
</template>
<script>
export default {
  mounted() {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      console.log('手机端')
      this.$store.dispatch('menu/setIsMobile', true);
    } else {
      console.log('pc端')
      this.$store.dispatch('menu/setIsMobile', false);
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }

}
</script>

<style lang="scss">
// 主题颜色全局定义
$thems-color: rgba(25, 44, 59, 0.85);

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.elMenus.fixed-navbar {
  font-size: 14px;
  // background: #1E937E;
  background: $thems-color;

  .el-menu-item:hover {
    background: #9dbddb !important;
    color: #fff !important;
    font-size: 16px !important;
    // background-color: #fff !important;
  }
}

.elMenus {

  // background:transparent;
  .el-menu-item:hover {
    color: #fff !important;
    font-size: 16px !important;
    background-color: #fff !important;
  }
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
