// store/modules/menu.js

import request from '@/utils/request';

const state = {
  idList: [],
};

const mutations = {
  setidList(state, data) {
    console.log('Setting idList:', data);
    state.idList = data;
  },
};

const actions = {
  async feachidList({ commit }) {
    try {
      const response = await request.get('/web/common/siteInfo');
      const dataAsArray = [response.data];
      console.log("response",dataAsArray)
      // 更新 Vuex 中的数据
      commit('setidList', dataAsArray);
    } catch (error) {
      // console.error('Error fetching id data:', error);
      // 处理请求失败的情况，比如显示错误信息等
    }
  },
};

const getters = {
    idList: (state) => state.idList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
