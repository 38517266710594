<template>
  <div class="video-container">
    <!-- <video playsinline   id="myVideoQ" v-if="video" ref="myVideoQ" controls @click="togglePlay" @timeupdate="updateProgress" @loadedmetadata="onVideoLoad">
      <source :src="video" type="video/mp4">
    </video> -->
    <video id="myVideoQ" v-if="video" ref="myVideoQ" controls @click="togglePlay" @timeupdate="updateProgress" @loadedmetadata="onVideoLoad">
  <source :src="video" type="video/mp4">
</video>
    <!-- <div v-if="loading" class="loading-overlay">视频正在加载中...</div> -->
  </div>
</template>

<script>
import request from '@/utils/request';

export default {
  data() {
    return {
      video: "",
      progress: 0,
      loading: true, // 新增加载状态
    };
  },
  mounted() {
    const storedData = localStorage.getItem('destinationData');
    if (storedData) {
      const destinationData = JSON.parse(storedData);
      // 使用 vuex 存储数据
      this.$store.commit('setDestinationData', destinationData);
    }
    // this.getData();
  },
  watch: {
    '$store.state.destinationData': {
      handler(oldParams, newParams) {
        // 监听路由参数的变化
        console.log("newParams", newParams, "oldParams", oldParams);
        const newId = newParams.id;
        const oldId = oldParams.id;

        if (newId !== oldId) {
          // 路由参数发生变化时，调用获取数据的方法
          // this.getData();
          window.scrollTo(0, 0);
        }
      },
      deep: true,
    },
  },
  computed: {
    destinationData() {
      return this.$store.state.destinationData;
    },
  },
  methods: {
    togglePlay() {
      const video = this.$refs.myVideoQ;
          //  var video = document.getElementById("myVideoQ");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    onVideoLoad() {
      // 视频加载完成后隐藏加载中提示
      this.loading = false;
    },
    updateProgress() {
      const video = this.$refs.myVideoQ;
      const progressBar = this.$refs.progressBar;

      const value = (video.currentTime / video.duration) * 100;
      this.progress = value;

      // progressBar.style.width = `${value}%`;
    },
  },
};
</script>

<style scoped>
.video-container {
  /* width: 100%;  */
  /* max-width: 500px; */
  /* margin: auto;  */
}

/* 其他样式可以根据需要进行调整 */
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 100%; */
  /* height: auto; */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 18px;
}
</style>
