<template>
  <div class="carousel-container" :style="{ 'background-image': `url(${backgroundSrc})`, 'background-size': 'cover' }">
    <!-- <div class="filter"></div> -->
    <div class="left-side">
      <div class="text-content">
        <div class="text-content1">
          {{ currentText }}
        </div>
        <div class="text-content2">
          {{ currentMsg }}
        </div>

      </div>
    </div>

    <!-- 右边占70%的div，用于实现轮播图 -->
    <div class="right-side">
      <swiper class="swiper  gallery-thumbs" :options="swiperOption" ref="swiper" v-if="carouselItems.length > 0">
        <swiper-slide v-for="(item, index) in carouselItems" :key="index" class="swiper-slide">
          <div :class="['slide-content']">
            <img :src="item.cover" class="img_img" alt="carousel-image" />
            <div class="content-overlay" style="color: #fff"></div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
      <div class="swiper-button-prevs" slot="button-prev"></div>
      <div class="swiper-button-nexts" slot="button-next"></div>
      <div class="message">
        {{ currentAA }}
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import request from '@/utils/request';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.less'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 30,
        loopedSlides: 5,
        loopAdditionalSlides: 3,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-nexts',
          prevEl: '.swiper-button-prevs'
        },
          // uniqueNavElements: true,
        on: {
          slideChangeTransitionEnd: () => {
            this.handleSlideTransitionEnd();
          },
        },
      },
      current: 0,
      backgroundSrc: "", // 用于左边div的背景图
      carouselItems: [],
      currentText: "",
      currentMsg: '',
      currentAA: '',
      currentBB: '',
    };
  },
  watch: {
    '$store.state.destinationData': {
      handler(oldParams, newParams) {
        // 监听路由参数的变化
        console.log("newParams", newParams, "oldParams", oldParams);
        const newId = newParams.id;
        const oldId = oldParams.id;

        if (newId !== oldId) {
          // 路由参数发生变化时，调用获取数据的方法
          // this.getData();
          this.getLocal()
          window.scrollTo(0, 0);
        }
      },
      deep: true, // 深度监听，确保能够监听到对象属性的变化
    },
  },
  computed: {
    // 从 Vuex 或其他状态管理工具中获取参数
    destinationData() {
      return this.$store.state.destinationData;
    },
  },
  mounted() {
    this.getLocal()
    // this.setBackgroundSrc(0);
    const storedData = localStorage.getItem('destinationData');
    if (storedData) {
      const destinationData = JSON.parse(storedData);
      // 使用 vuex 存储数据
      this.$store.commit('setDestinationData', destinationData);
    }
  },
  methods: {
    handleSlideTransitionEnd() {
      this.$nextTick(() => {
        let newcurrent = this.$refs.swiper.swiper.realIndex;
        console.log(newcurrent);
        this.setBackgroundSrc(newcurrent);
      })
    },
    setBackgroundSrc(index) {
      this.backgroundSrc = this.carouselItems[index].cover;
      this.currentText = this.carouselItems[index].title;
      this.currentMsg = this.carouselItems[index].desc;
      this.currentAA = this.carouselItems[index].title;
      // this.currentBB = this.carouselItems[index].bb;
    },
    // 获取当地主推
    getLocal() {
      let params = {
        page: 1,
        limit: 100,
        cate_id: 37
      }
      console.log("opopop", params)
      request.get("/web/article/getList", { params })
        .then(res => {
          console.log("res", res)
          if (res.code === 0) {
            this.carouselItems = res.data
            this.setBackgroundSrc(0);
          } else {
            //  this.$message.error(res.data.msg);
          }
        })
        // .catch(e => {
        //    
        // });
    },

  },
};
</script>

<style scoped lang="scss">
.filter {
  // CSS 部分代码
  position: absolute;
  width: 100%;
  height: 605px;
  background-color: transparent;
  backdrop-filter: blur(5px); //毛玻璃属性
}

.swiper {

  &.gallery-thumbs .swiper-slide {
    opacity: 1;
    // background: rgba(255, 255, 255, .5);

  }

  &.gallery-thumbs .swiper-slide-active {

    // background: rgba(255, 255, 255, 1.0);
    // opacity: .4;
    // opacity: none;
    .content-overlay {
      background: none;
    }
  }

  .swiper-slide {
    transform: scale(0.9);
    box-shadow: beige;
  }

  .swiper-slide-active {
    transform: scale(1.1) !important;
  }

  .swiper-slide-next {
    animation-name: scaleAnimation;
    /* 指定要应用的动画名称 */
    animation-duration: 1s;
    /* 设置动画持续时间为1秒 */
  }

}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }

  /* 起始状态为原始大小 */
  // 50% { transform: scale(0.95); } /* 在50%时间点将元素放大到1.5倍 */
  100% {
    transform: scale(0.9);
  }

  /* 结束状态返回原始大小 */
}

.carousel-container {
  display: flex;

  height: 605px;
}
@media screen and (max-width: 1000px) {
  .carousel-container{
    height: 505px;
  }

}
.left-side {
  width: 40%;
  height: 100%;
  background-size: cover;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  position: relative;
}

.text-content {
 height: 80%;
 color: #fff;
//  border: 1px solid red;
 display: flex;
 /* display: flex; */
 flex-direction: column;
 align-items: flex-start;
 justify-content: space-evenly;
 padding: 0 80px;
 margin-top: 10%;
}
@media screen and (max-width: 1000px) {
  .text-content{
    margin-top: 20%;
  }

}
.text-content1 {
  font-size: 36px;
  /* font-family: PingFang SC, PingFang SC; */
  font-weight: 500;
  color: #FFFFFF;
  line-height: 42px;
}

.text-content2 {
text-align: justify;
 font-size: 13px;
 font-family: Microsoft YaHei;
 font-weight: 400;
 color: #fff;
 position: relative;
 line-height: 21px;
 /* margin-top: -100px
; */
 line-height: 27px;
 top: -30px
;
}

.right-side {
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-left: 48px;
}

.swiper-slide {
  height: 447px;
  overflow: hidden;
  margin-top: 143px;
  transition: transform 0.3s;
}

.slide-content.selected {
  transform: scale(1.2);
  z-index: 1;
}

.slide-content {
  position: relative;
  width: 100%;
  height: 85%;
  // margin-top: 30px;
  transition: transform 0.3s ease;
}
@media screen and (max-width: 1000px) {
  .slide-content{
    height: 65%;
  
  }

}
.slide-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover_img {
  width: 100%;
  background: rgba(255, 255, 255, 1.0);
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.3s ease;
}

.selected .content-overlay {
  background: rgba(255, 255, 255, 0.1);
}

.swiper-button-prevs,
.swiper-button-nexts {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 42px;
  height: 42px;
  z-index: 9;
  // background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.swiper-button-nexts {

  right: 21px;
  /* Adjust as needed */
}

.swiper-button-nexts,
.swiper-container-rtl .swiper-button-prevs {
  background-image: url("@/assets/f1.png") !important;
  background-size: 100% 100%;

}

.swiper-button-prevs,
.swiper-container-rtl .swiper-button-nexts {
  background-image: url("@/assets/f2.png") !important;
  background-size: 100% 100%;

}

.swiper-button-prevs {
  left: 0;
  /* Adjust as needed */
}

.swiper-button-nexts::after,
.swiper-button-prevs::after {
  display: none;
}

.swiper-button-prevs::before,
.swiper-button-nexts::before {
  display: none;
}

.message {
  color: #fff;
  margin-top: 20px;
  font-size: 20px;
  /* font-family: Microsoft YaHei, Microsoft YaHei; */
  font-weight: 400;
  color: #FFFFFF;
  /* line-height: 23px; */
  display: flex;
  transform: translate(-50%, -50%);
  animation: moveAndBack 4s infinite alternate;
}

@keyframes moveAndBack {
  0% {
    transform: translateX(0);
  }

  // 50% {
  //   transform: translateX(10%);
  // }

  100% {
    transform: translateX(0);
  }
}

.messageB {
  color: #fff;
  margin-top: 8px;
  font-size: 16px;
  /* font-family: Microsoft YaHei, Microsoft YaHei; */
  font-weight: 400;
  color: #FFFFFF;
  /* line-height: 23px; */
  display: flex;
  transform: translate(-50%, -50%);
  animation: moveAndBack 4s infinite alternate;
}
</style>
