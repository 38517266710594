<template>
  <div style="padding: 48px 0;position: relative;">
    <div style="width: 90%;margin: 0 auto;">
      <swiper class="swiper" :options="swiperOption" v-if="localItems.length > 0">
        <swiper-slide v-for="(item,index) in localItems" :key="index">
          <div class="slide-content" v-if="item.cover" style="cursor: pointer;" @click="godetail(item.id)">
            <el-image class="a" :src="item.cover" fit="cover"></el-image>
            <div class="content-overlay" style="color: #fff"></div>
          </div>
          <div class="swiper_name" style="cursor: pointer;" @click="godetail(item.id)">{{ item.title }}</div>
          <div class="swiper_text" style="cursor: pointer;" @click="godetail(item.id)">{{ item.desc }}</div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>

    <div class="swiper-button-prev " slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </div>
</template>

<script>
import request from '@/utils/request';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import "swiper/swiper-bundle.css";
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 1,
        // loopedSlides: 5,
        // loopAdditionalSlides: 3,
        // loop: true,
        // loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
         uniqueNavElements: true,
      },
      localItems: [
      
      ],
    };
  },
  mounted() {
    this.getLocal()
    const storedData = localStorage.getItem('destinationData');
    if (storedData) {
      const destinationData = JSON.parse(storedData);
      // 使用 vuex 存储数据
      this.$store.commit('setDestinationData', destinationData);
    }
  },
  watch: {
    '$store.state.destinationData': {
      handler(oldParams, newParams) {
        // 监听路由参数的变化
        console.log("newParams", newParams, "oldParams", oldParams);
        const newId = newParams.id;
        const oldId = oldParams.id;

        if (newId !== oldId) {
          // 路由参数发生变化时，调用获取数据的方法
          // this.getData();
          this.getLocal()
          window.scrollTo(0, 0);
        }
      },
      deep: true, // 深度监听，确保能够监听到对象属性的变化
    },
  },
  computed: {
    // 从 Vuex 或其他状态管理工具中获取参数
    destinationData() {
      return this.$store.state.destinationData;
    },
  },
  methods: {
    godetail(e) {
      this.$router.push({
        path: "/InformationDetile",
        query: {
          id: e
        }
      })
    },
    // 获取当地主推
    getLocal() {
      let params = {
        page: 1,
        limit: 100,
        cate_id: 36
      }
      console.log("opopop", params)
      request.get("/web/article/getList", { params })
        .then(res => {
          console.log("res", res)
          if (res.code === 0) {
            this.localItems = res.data
            console.log("文章列表localItems", this.localItems)
          } else {
             this.$message.error(res.data.msg);
          }
        })
        .catch(e => {
          //  
        });
    },
  }
};
</script>

<style scoped lang="scss">
.swiper_name {
      text-align: center;
    font-size: 12px;
    line-height: 17px;
    margin-top: 6px;
}

.swiper_text {
  text-align: center;
  font-size: 14px;
  line-height: 1;
  margin-top: 6px;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-button-prev::before,
.swiper-button-next::before {
  display: none;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("@/assets/f1.png") !important;
  background-size: 100% 100%;

}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("@/assets/f2.png") !important;
  background-size: 100% 100%;

}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  width: 54px;
  height: 54px;
  // background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .swiper-button-next{
   right:-4%;
    position: absolute;
  top: 50%;
  transform: translateY(-50%);
  }

}
@media screen and (max-width: 1000px) {
  .swiper-button-prev{
   left:-4%;
    position: absolute;
  top: 50%;
  transform: translateY(-50%);
  }

}
.a {
width: 100%; 
// height: 285px;
  height: 220px;
  transition: transform 0.3s ease-out;
  position: relative;
  z-index: 2; 
}
@media screen and (max-width: 1000px) {
  .a{
    height: 156px;
  }

}

.slide-content:hover {
  box-shadow: 12px 12px 24px 0px rgba(0, 0, 0, 0.2), -12px -12px 24px 0px rgba(0, 0, 0, 0.2);

  .a {
    transform: scale(1.1);
  }
}
</style>
