<template>
  <div class="navbar-wrapper fixed-navbar">
    <!-- ~~ 修改为固定的导航  恢复只需要解开注释代码 -->
    <!-- ~~ <div class="navbar-wrapper" :class="{ 'fixed-navbar': isNavbarFixed }"></div> -->
    <div class="total1">
      <!-- ~~<div :class="isNavbarFixed ? 'total1' : 'total'"> -->
      <!-- <div> -->
      <img src="@/assets/logo.png" v-if="menuData" class="logo_img" alt="">
      <!-- </div> -->
      <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="transparent"
        text-color="#fff" font-size="14">
        <div class="DD" style="display:flex;    flex-wrap: wrap;" v-for="item in menuData" :key="item.id"
          :index="String(item.id)">
          <el-menu-item popper-class='MM' class="AS menu-item-h" v-if="item.child && item.child.length == 0"
            :index="item.id + 'i'" @click.native="godetule(item)">{{ item.name }}</el-menu-item>

          <!-- ~~ :popper-class="isNavbarFixed ? 'elMenus fixed-navbar' : 'elMenus'"  -->
          <el-submenu v-if="item.child && item.child.length !== 0" popper-class="elMenus fixed-navbar"
            :class="{ 'fixed-menu': isNavbarFixed }" :index="String(item.id + 'a')" trigger="click" :show-arrow="false">
            <template slot="title">{{ item.name }}</template>
            <el-menu-item :index="`${item.id}-child-${childs.id}`" @click.native="godetule(childs)"
              v-for="childs in item.child" :key="childs.id">
              {{ childs.name }}
            </el-menu-item>
          </el-submenu>
        </div>
        <!-- <el-menu-item   v-if="item.child && item.child.length !== 0" index="1">{{item.name}}</el-menu-item> 
    <el-submenu
      v-if="item.child && item.child.length !== 0"
      :popper-class="isNavbarFixed ? 'elMenus fixed-navbar' : 'elMenus'"
      :class="{ 'fixed-menu': isNavbarFixed }"
      :index="String(item.id)"
      trigger="click"
      :show-arrow="false"
    >
      <template slot="title">{{ item.name }}</template>
      <el-menu-item
        :index="`${item.id}-child-${childs.id}`"
        @click.native="godetule(childs)"
        v-for="childs in item.child"
        :key="childs.id"
      >
        {{ childs.name }}
      </el-menu-item>
    </el-submenu>
  </el-menu-item> -->
      </el-menu>
      <!-- </el-menu> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import request from '@/utils/request';
export default {
  computed: {
    ...mapGetters('menu', ['menuData']),
  },
  data() {
    return {
      isNavbarFixed: false,
      tabList: [],
      activeIndex: '1',
      aaaa: false,
    };
  },
  created() {
    this.fetchData();
    // console.log("rtrtrtrtrtr",this.idList)
  },
  mounted() {
    // this.fetchMenuData();
    //  this.getData()
    window.addEventListener('scroll', this.handleScroll);
    console.log("rtrtrtrtrtr", this.menuData)
  },
  // created() {
  //   this.fetchMenuData();
  //   console.log("rtrtrtrtrtr",this.menuData)
  // },
  methods: {
    touchstart(e) {
      console.log("eeeee", e)
      this.aaaa = true
    },
    ght() {

    },
    ...mapActions('menu', ['fetchMenuData']),
    // 获取表头
    // getData(){
    //   request.get("/web/itemcate/getCateList")
    //     .then(res => {
    //       console.log("res",res)
    //       if (res.code === 0) {
    //         this.tabList = res.data
    //         console.log("栏目列表",this.tabList)
    //       } else {
    //          this.$message.error(res.data.msg);
    //       }
    //     })
    //     .catch(e => {
    //        
    //     });
    // },
    async fetchData() {
      await this.fetchMenuData();

    },
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      this.isNavbarFixed = scrollPosition > 200;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // gogogo(index) {
    //   console.log("dakail", index)
    // },
    godetule(e) {
      //  this.aaaa = false;
      this.$set(this.menuData, 0, { ...this.menuData[0] });
      console.log("eeeeeee", e);
      if (e.show_type === 3) {
        // 外部链接跳转
        // window.location.href = e.url; 
        window.open(e.url, '_blank')
        return;
      }
      // const routes = {
      //     "首页": "/",
      //     "景区概况": "/introduce/bailitotal",
      //     "柏里百景": "/introduce/bailiBaijing",
      //     "全景VR游": "/introduce/vr",
      //     "投诉建议":"/ServiceCenter",
      //     "联系我们":"/lianxi",
      //     "招标信息":"/NewsInformation/inviteTenders",
      //     "招聘信息":"/NewsInformation/inviteList"
      // };
      //  const routes = {
      //     1: "/",
      //     8: "/introduce/bailitotal",
      //     9: "/introduce/bailiBaijing",
      //     "全景VR游": "/introduce/vr",
      //     31:"/ServiceCenter",
      //     32:"/lianxi",
      //     34:"/NewsInformation/inviteTenders",
      //     35:"/NewsInformation/inviteList"
      // };
      const routes = {
        1: "/",
        2: "/introduce/bailitotal",
        3: "/introduce/bailiBaijing",
        4: "/ServiceCenter",
        5: "/lianxi",
        6: "/NewsInformation/inviteTenders",
        7: "/NewsInformation/inviteList"
      };

      const path = routes[e.style_other] || (e.show_type === 1 ? (e.style == 1 ? "/Homestay/Stonekiln" : "/NewsInformation") : (e.style_detail == 1 ? "/Homestay/stonekillDetile" : "/InformationDetile"));
      this.$store.commit('setDestinationData', {
        id: e.id,
        cover: e.cover,
        title: e.name,
        msg: e.note,
        style: e.style,
        style_detail: e.style_detail
      });
      console.log("数据数据数据", e.note)
      // 将数据存储在localStorage中
      localStorage.setItem('destinationData', JSON.stringify({

        id: e.id,
        cover: e.cover,
        title: e.name,
        msg: e.note,
        style: e.style,
        style_detail: e.style_detail
      }));
      console.log("跳转页面", e);
      this.$router.push({
        path,
        params: {
          id: e.id
        }
      }).catch(() => { });

      // console.log("跳转页面", e);
    }
  }
};
</script>
<style scoped>
.total {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  /* 新增 */
  margin-top: 64px;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  /* outline: 0; */
  color: #fff;
}

.total1 {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /* 新增 */
  /* margin-top: 5px; */
}

.navbar-wrapper.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  line-height: 20px;
  /* background-color: #1E937E; */
  background: rgba(25, 44, 59, 0.85);
  /* Change this to your desired background color */
  /* Add any additional styles for the fixed navbar here */
}

.el-submenu.fixed-navbar {
  position: static;
  /* 新增 */
}

.logo_img {
  width: 50px;
  height: 50px;
  margin-right: 50px;
  border-radius: 50%;
  /* background: #fff;
  padding: 4px;
  box-sizing: border-box; */
}

.el-menu.el-menu--horizontal {
  display: flex !important;
}

.el-menu--horizontal .el-menu .el-menu-item.is-active {
  color: #fff !important;
}
</style>
<style>
.el-menu-item.is-active {
  color: #fff !important;
  
}

.el-submenu__title {
  color: #fff !important;
}

.el-submenu .is-active {
  color: #fff !important;
}

.el-submenu__title:hover {
  background-color: transparent !important;
  color: #fff !important;
  font-size: 16px!important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}

.el-menu-demo .el-menu-item {
  /* color: red !important; */
  font-weight: 500 !important;
  font-size: 14px;

  border-bottom-color: transparent !important;
  background-color: transparent !important;
}

.el-menu-demo .el-menu-item :hover,
.el-menu-demo .el-menu-item a:hover {
  outline: 0 !important;
  color: #fff !important;
  background-color: transparent !important;
}

.el-menu-demo .el-menu-item>.el-menu-item {
  background-color: #fff !important;
  color: #000 !important;
}

.el-menu-item .is-active {
  /* color: red !important; */
  border-bottom: 0px !important;
  font-size: 14px !important;
}

.el-submenu__icon-arrow {
  display: none !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-submenu__title {
  line-height: 60px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.el-dropdown-menu__item,
.el-menu-item {
  font-size: 14px;
  padding: 0 10px !important;
}

.el-submenu__title {
  padding: 0 10px !important;
}

.menu-item-h {
  height: 60px !important;
  line-height: 60px !important;
}
.menu-item-h:hover{
  font-size: 16px!important;
  color: #fff!important;
  justify-content: space-between;
}
</style>
