import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import getPageTitle from "@/utils/get-page-title";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
    },
    component: HomeView,
  },
  {
    path: "/introduce/bailitotal",
    name: "",
    meta: {
      title: "景区概括",
    },
    component: () => import("../views/introduce/bailitotal.vue"),
  },
  {
    meta: {
      title: "柏里百景",
    },

    path: "/introduce/bailiBaijing",
    name: "",
    component: () => import("../views/introduce/bailiBaijing.vue"),
  },
  {
    meta: {
      title: "全景VR游",
    },

    path: "/introduce/vr",
    name: "",
    component: () => import("../views/introduce/vr.vue"),
  },
  {
    meta: {
      title: "花乡间民宿",
    },
    path: "/Homestay/Courtyard",
    name: "",
    component: () => import("../views/Homestay/Courtyard.vue"),
  },
  {
    meta: {
      title: "石窑主题民宿",
    },
    path: "/Homestay/Stonekiln",
    name: "",
    component: () => import("../views/Homestay/Stonekiln.vue"),
  },
  {
    meta: {
      title: "度假酒店",
    },
    path: "/Homestay/ResortHotel",
    name: "",
    component: () => import("../views/Homestay/ResortHotel.vue"),
  },
  {
    meta: {
      title: "休闲娱乐",
    },
    path: "/entertainment/entertainment",
    name: "",
    component: () => import("../views/entertainment/entertainment.vue"),
  },
  {
    meta: {
      title: "当季主推",
    },
    path: "/entertainment/promotion",
    name: "",
    component: () => import("../views/entertainment/promotion.vue"),
  },
  {
    meta: {
      title: "详情",
    },
    path: "/Homestay/stonekillDetile",
    name: "",
    component: () => import("../views/Homestay/stonekillDetile.vue"),
  },
  {
    meta: {
      title: "服务中心",
    },
    path: "/ServiceCenter",
    name: "",
    component: () => import("../views/ServiceCenter/service.vue"),
  },
  {
    meta: {
      title: "服务中心",
    },
    path: "/lianxi",
    name: "",
    component: () => import("../views/ServiceCenter/lianxi.vue"),
  },
  {
    meta: {
      title: "新闻中心",
    },
    path: "/NewsInformation",
    name: "",
    component: () => import("../views/NewsInformation/Information.vue"),
  },
  {
    meta: {
      title: "",
    },
    title: "",
    path: "/InformationDetile",
    name: "InformationDetile",
    component: () => import("../views/NewsInformation/InformationDetile.vue"),
  },
  {
    meta: {
      title: "",
    },
    title: "",
    path: "/Leisure",
    name: "Leisure",
    component: () => import("../views/Leisure/Leisure.vue"),
  },
  {
    meta: {
      title: "星宿海露营",
    },
    path: "/camp",
    name: "camp",
    component: () => import("../views/camp/camp.vue"),
  },
  {
    meta: {
      title: "特色研学",
    },
    path: "/Research/Research",
    name: "Research",
    component: () => import("../views/Research/Research.vue"),
  },
  {
    meta: {
      title: "招标信息",
    },
    path: "/NewsInformation/inviteTenders",
    name: "inviteTenders",
    component: () => import("../views/NewsInformation/inviteTenders.vue"),
  },
  {
    meta: {
      title: "招标列表",
    },
    path: "/NewsInformation/inviteList",
    name: "inviteList",
    component: () => import("../views/NewsInformation/inviteList.vue"),
  },
  {
    meta: {
      title: "招标详情",
    },
    path: "/NewsInformation/inviteDetail",
    name: "inviteDetail",
    component: () => import("../views/NewsInformation/inviteDetail.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = getPageTitle(to.meta.title);
  next();
});

export default router;
