import Vue from 'vue'
import Vuex from 'vuex'
import menuModule from './modules/menu';
import idModule from "./modules/idList"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    destinationData: {},
  },
  getters: {
  },
  mutations: {
    setDestinationData(state, data) {
      state.destinationData = data;
    },
  },
  actions: {
  },
  modules: {
    menu: menuModule,
    idModule:idModule
  }
})
