import { render, staticRenderFns } from "./video.vue?vue&type=template&id=508d508c&scoped=true"
import script from "./video.vue?vue&type=script&lang=js"
export * from "./video.vue?vue&type=script&lang=js"
import style0 from "./video.vue?vue&type=style&index=0&id=508d508c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508d508c",
  null
  
)

export default component.exports