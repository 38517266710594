<template>
  <div class="thumb-example">
    <!-- swiper1 -->
    <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
      <swiper-slide
        v-for="(item, index) in carouselItems"
        :key="index"
        :style="{ backgroundImage: `url(${item.cover})`, cursor: 'pointer' }"
      ></swiper-slide>
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
      <swiper-slide
        v-for="(item, index) in carouselItems"
        :key="index"
        :style="{ backgroundImage: `url(${item.cover})`, cursor: 'pointer' }"
      ></swiper-slide>
    </swiper>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import request from "@/utils/request";

export default {
  name: "swiper-example-thumbs-gallery",
  title: "Thumbs gallery with Two-way control",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 0, // 设置为0，因为循环项的数量由实际的轮播项数量决定
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 0, // 设置为0，因为循环项的数量由实际的轮播项数量决定
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      carouselItems: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getLocal();
    });
  },
  methods: {
    // 获取当地主推
    getLocal() {
      let params = {
        page: 1,
        limit: 100,
        cate_id: 37,
      };
      request
        .get("/web/article/getList", { params })
        .then((res) => {
          if (res.code === 0) {
            this.carouselItems = res.data;
            this.initSwiper();
          } else {
             this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          //  
        });
    },
    initSwiper() {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;

      // 更新 swiperOption 中的 loopedSlides
      this.$set(this.swiperOptionTop, "loopedSlides", this.carouselItems.length);
      this.$set(this.swiperOptionThumbs, "loopedSlides", this.carouselItems.length);

      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    },
  },
};
</script>

  
<style lang="scss" scoped>
.thumb-example {
    height: 330px;
    // background-color: $black;
}

.swiper {
    .swiper-slide {
        background-size: cover;
        background-position: center;

        // &.slide-1 {
        //     background-image: url("@/assets/headerTop.png");
        //     background-size: 100% 100%;
        // }

        // &.slide-2 {
        //     background-image: url("@/assets/b8.png");
        //     background-size: 100% 100%;
        // }

        // &.slide-3 {
        //     background-image: url("@/assets/Z4.jpg");
        //     background-size: 100% 100%;
        // }

        // &.slide-4 {
        //     background-image: url("@/assets/h3.png");
        //     background-size: 100% 100%;
        // }

        // &.slide-5 {
        //     background-image: url("@/assets/Z3.jpg");
        //     background-size: 100% 100%;
        // }
    }

    &.gallery-top {
        height: 100%;
        width: 100%;
    }

    &.gallery-thumbs {
        height: 20%;
        box-sizing: border-box;
        transform: translateY(-110%);
        width: 60%;
        margin: 0 auto;
        background-color: rgba($color: #fff, $alpha: .3);
        border-radius: 6px;
        padding: 6px;
    }

    &.gallery-thumbs .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    &.gallery-thumbs .swiper-slide-active {
        opacity: 1;
    }
}

.swiper.gallery-thumbs .swiper-slide-active {
    border: 1px solid #fff;
    border-radius: 3px;
}
</style>