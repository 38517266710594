import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './utils/reset.css'; // reset.css
import request from "@/utils/request";
import VueTouch from 'vue-touch';

Vue.use(VueTouch, { name: 'v-touch' });

import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.request=request
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  Swiper,
  render: h => h(App)
}).$mount('#app')
