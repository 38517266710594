<template>
  <div class="home" :class="$store.state.menu.isMobile ? 'height-mod' : ''">
    <div class="swiper-container" :class="$store.state.menu.isMobile ? 'height-mod' : ''" ref="swiperContainer2">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in carouselItems" :key="index + 'a'">
          <!-- style="height: 100vh; width: 100%; object-fit: cover" -->
          <div :class="$store.state.menu.isMobile ? 'swiper-height-mod' : 'swiper-height-pc'" v-if="item.type === 3">
            <video id="myVideo" ref="myVideo" autoplay muted @click="togglePlay" @loadedmetadata="onVideoLoad"
              :poster="item.video_cover">
              <source :src="item.cover" type="video/mp4" />
            </video>
            <!-- <video id="myVideo" ref="myVideo" autoplay @click="togglePlay" @loadedmetadata="onVideoLoad">
      <source :src="item.cover" type="video/mp4">
    </video> -->
          </div>
          <!-- style="height: 100vh; width: 100%; object-fit: cover" -->
          <div :class="$store.state.menu.isMobile ? 'swiper-height-mod' : 'swiper-height-pc'" v-else>
            <img :src="item.cover" @error="handleImageError" alt="" class="slide-image" />
          </div>
        </div>
      </div>
      <div class="swiper-paginationA"></div>
      <div ref="A1" style="position: absolute" class="A1">
        {{ currentText }}
      </div>
      <div ref="A2" style="position: absolute" class="A2">
        {{ currentMsg }}
      </div>
    </div>
    <div class="text-container">
      <Header />
      <!-- <headerM v-if="$store.state.menu.isMobile"></headerM>
      <Header  v-else/> -->
    </div>
  </div>
</template>
<script>
import Header from "@/components/header.vue";
import headerM from "@/components/headerM.vue";
import Swiper from "swiper";
import request from "@/utils/request";
import "swiper/swiper-bundle.css";

export default {
  name: "HomeView",
  components: {
    Header,
    headerM
  },
  data() {
    return {
      dataCollection: {},
      carouselItems: [
        // {
        //   id: 1,
        //   cover: require("@/assets/headerTop.png"),
        //   type: 1,
        //   title: "柏里水乡",
        //   msg: "柏里水乡   宣传标语",
        // },
        // {
        //   id: 2,
        //   cover: require("@/assets/asdgg.mp4"),
        //   title: "百里水乡22",
        //   type: 3,
        //   msg: "柏里水乡 22  宣传标语",
        // },
      ],
      swiper: null,
      activeIndex: 0,
      currentText: "",
      currentMsg: "",
      currentRemok: "",
      loading: false,
    };
  },
  mounted() {
    this.getData();
    // this.initSwiper();
    // this.updateText(0);
  },
  watch: {
    "swiper.activeIndex"(newIndex) {
      this.updateText(newIndex);

      // 在下一帧中添加 scale-animation 类
      this.$nextTick(() => {
        if (
          this.$refs.swiperContainer2 &&
          this.$refs.swiperContainer2.classList
        ) {
          this.$refs.swiperContainer2.classList.remove("scale-animationA");
        }

        this.$refs.A1.classList.remove("scale-animation");
        this.$refs.A2.classList.remove("scale-animation2");
        // this.$refs.A3.classList.remove('scale-animation3');
        // this.$refs.AA.classList.remove('scale-animationA');
        setTimeout(() => {
          this.$refs.swiperContainer2.classList.add("scale-animationA");
          this.$refs.A1.classList.add("scale-animation");
          this.$refs.A2.classList.add("scale-animation2");
          // this.$refs.A3.classList.add('scale-animation3');
          // this.$refs.AA.classList.add('scale-animationA');
        }, 0);
      });
    },
  },
  methods: {
    onVideoLoad() {
      this.loading = false;
      // 获取 video 元素
      // const videoElement = document.getElementById('myVideo');

      // // 监听 loadeddata 事件确保视频第一帧加载完成
      // videoElement.addEventListener('loadeddata', () => {
      //   // 使用 canvas 绘制视频的第一帧作为封面
      //   const canvas = document.createElement('canvas');
      //   canvas.width = videoElement.videoWidth;
      //   canvas.height = videoElement.videoHeight;
      //   const ctx = canvas.getContext('2d');

      //   // 在视频加载完成后，将当前视频帧绘制到 canvas 上
      //   ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      //   // 将 canvas 转换为 data URL，并设置为视频的封面
      //   const dataURL = canvas.toDataURL();
      //   const imgUrl = this.getFileFromBase64(dataURL, "kefu.jpeg");
      //   console.log("dataURL", imgUrl);

      //   videoElement.setAttribute('poster', dataURL);
      // });
    },

    // base64转图片
    getFileFromBase64(base64URL, filename) {
      var arr = base64URL.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: "image/png" });
    },

    togglePlay() {
      var video = document.getElementById("myVideo");
      // const video = this.$refs.myVideo;
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    },
    // onVideoLoad() {
    //   this.loading = false;
    // },
    handleImageError(item) {
      item.cover = " ";
    },
    // 获取轮播图
    getData() {
      request
        .get("/web/common/indexInfo")
        .then((res) => {
          console.log("res", res);
          if (res.code === 0) {
            this.dataCollection = res.data;
            this.carouselItems = res.data.loop;
            this.$nextTick(() => {
              this.initSwiper();
              this.updateText(0);
            });
            console.log("78979", this.dataCollection);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          //
        });
    },

    initSwiper() {
      this.swiper = new Swiper(this.$refs.swiperContainer2, {
        slidesPerView: 1,
        // slidesPerView: 1,
        pagination: {
          el: ".swiper-paginationA",
          clickable: true,
        },
        on: {
          slideChange: () => {
            console.log("slide changed");
            const currentIndex = this.swiper.activeIndex;
            this.updateText(currentIndex);
          },
        },
      });
      // 手动更新 Swiper
      // this.swiper.update();
      // this.swiper.updateSlides();
      // this.swiper.updateProgress();
      // this.swiper.updateSlidesClasses();
      // this.swiper.updatePagination();
      // this.swiper.updateScrollbar();
    },
    updateText(index) {
      //  this.$refs.A1.classList.remove('scale-animation');
      console.log("index8989898", index);
      this.currentText = this.carouselItems[index].title;
      this.currentMsg = this.carouselItems[index].description;
      this.currentRemok = this.carouselItems[index].description;
      console.log("名字", this.currentText);
    },
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>



<style lang="scss" scoped>
// 如果是手机端 不全屏
.height-mod {
  height: 30vh !important;
}

.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

// @media screen and (max-width: 1000px) {
//  .text-container{
//     width: 100%;
//     left: 0 !important;
//     right: 0 !important;
//     padding: 0 !important;
//  }
// }
// @media screen and (max-width: 1000px) {
//  .logo_img{
//  margin-right: 12px !important;
//   }
// }
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* width: 100%; */
  /* height: auto; */
}

/* 根据需要添加样式 */
.swiper-container {
  overflow-x: hi;
  height: 100vh;

  &.scale-animationA {
    animation: scaleUpA 8.8s forwards;
    animation-delay: 0.1s;
  }

  @keyframes scaleUpA {
    // 0% {
    //   transform: scale(0.8);
    // }

    50% {
      transform: scale(0.5);
    }

    100% {
      transform: scale(1);
    }
  }
}

.swiper-slide {
  &.scale-animationA {
    animation: scaleUpA 1.8s forwards;
    animation-delay: 0.1s;
  }

  @keyframes scaleUpA {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }
}

.slide-image {
  width: 100%;
  height: 100%;
  //    &.scale-animationA {
  //     animation: scaleUpA 1.8s forwards;
  //     animation-delay: 0.1s; 
  //   }

  //   @keyframes scaleUpA {
  //   0% {
  //     transform:  scale(1);
  //   }
  //   50% {
  //     transform:  scale(1.3);
  //   }
  //   100% {
  //     transform:  scale(1);
  //   }
  //   }
}

.text-container {
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  text-align: center;
  color: #fff;
  z-index: 999;
  //   background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.A1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 108px;
  justify-content: center;
  align-items: center;
  user-select: none;

  &.scale-animation {
    animation: scaleUp 0.8s forwards;
    animation-delay: 0.1s;
  }

  @keyframes scaleUp {
    0% {
      transform: translate(-50%, -50%) translateY(0);
    }

    50% {
      transform: translate(-50%, -50%) translateY(-30px);
    }

    100% {
      transform: translate(-50%, -50%) translateY(0);
    }

    // to {
    //   transform: scale(1.5);
    // }
  }
}

.A2 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  //  width: calc( 100% - 365px );
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 82px;
  line-height: 42px;
  letter-spacing: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  &.scale-animation2 {
    animation: scaleUp2 0.8s forwards;
    animation-delay: 0.1s;
  }

  @keyframes scaleUp2 {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }

    50% {
      transform: translate(-50%, -50%) scale(1.3);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }

    // to {
    //   transform: scale(1.5);
    // }
  }
}

.A3 {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // z-index: 1;
  // transform: translate(-50%, -50%);
  // font-size: 14px;
  // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  // font-weight: 400;
  // color: #ffffff;
  // line-height: 21px;
  // margin-top: 112px;

  &.scale-animation3 {
    animation: scaleUp3 0.8s forwards;
    animation-delay: 0.1s;
  }

  @keyframes scaleUp3 {
    0% {
      transform: translate(-50%, -50%) translateY(0px);
    }

    50% {
      transform: translate(-50%, -50%) translateY(10px);
    }

    100% {
      transform: translate(-50%, -50%) translateY(0);
    }
  }
}

.swiper-height-pc {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.swiper-height-mod {
  height: 30vh;
  width: 100%;
  object-fit: cover;
}
</style>
<style>
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #ffffff !important;
}

::deep.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #fff !important;
  /* background: none !important; */
  opacity: 0.2;
}</style>